import * as React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import Seo from "../components/SEO"
import Button from "../components/Button/Button"

const error = () => {
  return (
    <>
      <Seo title="Message Sent" />
      <Layout>
        <div className="section">
          <div className="container container__tight">
            <h1>Message Sent.</h1>
            <h3>We will be in touch shortly</h3>

            <Button text="Return Home" to="/" as={Link} />
          </div>
        </div>
      </Layout>
    </>
  )
}

export default error
